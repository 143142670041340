import { Injectable } from '@angular/core';
import { MINUTE, HOUR, DAY, WEEK, MONTH, YEAR } from './utils';

export type Unit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';

export type Suffix = 'ago' | 'from now' | '';

export type StringOrFn =
  | ((value: number, millisDelta: number) => string)
  | string;

export type NumberArray = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

const defaultFormattter = function (then: number): {
  value: number;
  unit: Unit;
  suffix: Suffix;
} {
  const now = Date.now();
  const seconds = Math.round(Math.abs(now - then) / 1000);
  // const suffix: Suffix = then < now ? 'ago' : 'from now';
  const suffix: Suffix = '';

  const [value, unit]: [number, Unit] =
    seconds < MINUTE
      ? [Math.round(seconds), 'second']
      : seconds < HOUR
        ? [Math.round(seconds / MINUTE), 'minute']
        : seconds < DAY
          ? [Math.round(seconds / HOUR), 'hour']
          : seconds < WEEK
            ? [Math.round(seconds / DAY), 'day']
            : seconds < MONTH
              ? [Math.round(seconds / WEEK), 'week']
              : seconds < YEAR
                ? [Math.round(seconds / MONTH), 'month']
                : [Math.round(seconds / YEAR), 'year'];

  return { value, unit, suffix };
};

export abstract class TimeagoFormatter {
  abstract format(then: number): string;
}

@Injectable()
export class TimeagoDefaultFormatter extends TimeagoFormatter {
  format(then: number): string {
    const { suffix, value, unit } = defaultFormattter(then);
    return this.parse(value, unit, suffix);
  }

  private parse(value: number, unit: Unit, suffix: Suffix): string {
    if (value !== 1) {
      unit += 's';
    }
    return value + ' ' + unit + ' ' + suffix;
  }
}
