import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { coerceBooleanProperty, dateParser, isDefined } from './utils';
import { TimeagoClock } from './timeago.clock';
import { TimeagoFormatter } from './timeago.formatter';

@Directive({
  selector: '[appTimeago]',
  exportAs: 'timeago'
})
export class TimeagoDirective implements OnChanges, OnDestroy {
  private intlSubscription: Subscription;
  private clockSubscription: Subscription;

  /**
   * Emits on:
   * - Input change
   * - Intl change
   * - Clock tick
   */
  stateChanges = new Subject<any>();

  /** The Date to display. An actual Date object or something that can be fed to new Date. */
  @Input()
  get date(): any {
    return this._date;
  }

  set date(date: any) {
    this._date = dateParser(date).valueOf();
    if (this._date) {
      if (this.clockSubscription) {
        this.clockSubscription.unsubscribe();
        this.clockSubscription = undefined;
      }
      this.clockSubscription = this.clock
        .tick(this.date)
        .pipe(filter(() => this.live, this))
        .subscribe((value) => this.stateChanges.next(value));
    } else {
      throw new SyntaxError(
        `Wrong parameter in TimeagoDirective. Expected a valid date, received: ${date}`
      );
    }
  }

  private _date: number;

  /** If the directive should update itself over time */
  @Input()
  get live(): boolean {
    return this._live;
  }

  set live(live: boolean) {
    this._live = coerceBooleanProperty(live);
  }

  private _live = true;

  constructor(
    private cd: ChangeDetectorRef,
    formatter: TimeagoFormatter,
    element: ElementRef,
    private clock: TimeagoClock
  ) {
    this.stateChanges.subscribe(() => {
      this.setContent(element.nativeElement, formatter.format(this.date));
      this.cd.markForCheck();
    });
  }

  ngOnChanges(value) {
    this.stateChanges.next(value);
  }

  setContent(node: any, content: string) {
    if (isDefined(node.textContent)) {
      node.textContent = content;
    } else {
      node.data = content;
    }
  }

  ngOnDestroy() {
    if (this.intlSubscription) {
      this.intlSubscription.unsubscribe();
      this.intlSubscription = undefined;
    }
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = undefined;
    }
    this.stateChanges.complete();
  }
}
